import React from 'react';

import Layout from '../components/layout/Layout';
import Container from '../components/layout/Container';
import SEO from '../components/misc/seo';

function Behandlungskonzept() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />
      <section className="bg-white text-green-800">
        <div className="py-24">
          <Container>
            <div className="text-center mb-12">
              <h1 className="font-serif text-4xl sm:text-6xl">
                Datenschutzerklärung
              </h1>
            </div>
            <div className="md:max-w-4xl mx-auto text-center">
              <p>
                Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere
                der EU-Datenschutzgrundverordnung (DSGVO), ist:
              </p>
              <p>
                Marianne Marschollek <br />
                Aurikelweg 19 <br />
                53840 Troisdorf
              </p>
              <h2>Ihre Betroffenenrechte</h2>
              <p>
                Unter den angegebenen Kontaktdaten unseres
                Datenschutzbeauftragten können Sie jederzeit folgende Rechte
                ausüben:
              </p>
              <ul>
                <li>
                  Auskunft über Ihre bei uns gespeicherten Daten und deren
                  Verarbeitung (Art. 15 DSGVO),
                </li>
                <li>
                  Berichtigung unrichtiger personenbezogener Daten (Art. 16
                  DSGVO),
                </li>
                <li>
                  Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),
                </li>
                <li>
                  Einschränkung der Datenverarbeitung, sofern wir Ihre Daten
                  aufgrund gesetzlicher Pflichten noch nicht löschen dürfen
                  (Art. 18 DSGVO),
                </li>
                <li>
                  Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art.
                  21 DSGVO) und
                </li>
                <li>
                  Datenübertragbarkeit, sofern Sie in die Datenverarbeitung
                  eingewilligt haben oder einen Vertrag mit uns abgeschlossen
                  haben (Art. 20 DSGVO).
                </li>
              </ul>
              <p>
                Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese
                jederzeit mit Wirkung für die Zukunft widerrufen.
              </p>
              <p>
                Sie können sich jederzeit mit einer Beschwerde an eine
                Aufsichtsbehörde wenden, z. B. an die zuständige
                Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die
                für uns als verantwortliche Stelle zuständige Behörde.
              </p>
              <p>
                Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen
                Bereich) mit Anschrift finden Sie unter:{' '}
                <a
                  href="https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html"
                  target="_blank"
                  rel="noopener nofollow noreferrer"
                >
                  https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html
                </a>
                .
              </p>
              <p></p>
              <h2>Verwendung von Scriptbibliotheken (Google Webfonts)</h2>
              <p>
                Um unsere Inhalte browserübergreifend korrekt und grafisch
                ansprechend darzustellen, verwenden wir auf dieser Website
                „Google Web Fonts“ der Google LLC (1600 Amphitheatre Parkway,
                Mountain View, CA 94043, USA; nachfolgend „Google“) zur
                Darstellung von Schriften.
              </p>
              <p>
                Weitere Informationen zu Google Web Fonts finden Sie unter{' '}
                <a
                  href="https://developers.google.com/fonts/faq"
                  rel="noopener nofollow noreferrer"
                  target="_blank"
                >
                  https://developers.google.com/fonts/faq
                </a>{' '}
                und in der Datenschutzerklärung von Google:{' '}
                <a
                  href="https://www.google.com/policies/privacy/"
                  rel="noopener nofollow noreferrer"
                  target="_blank"
                >
                  https://www.google.com/policies/privacy/
                </a>
                .
              </p>
              <p></p>
              <h2>SSL-Verschlüsselung</h2>
              <p></p>
              <h2>Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h2>
              <h3>Einzelfallbezogenes Widerspruchsrecht</h3>
              <p>
                Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
                Situation ergeben, jederzeit gegen die Verarbeitung Sie
                betreffender personenbezogener Daten, die aufgrund Art. 6 Abs. 1
                lit. f DSGVO (Datenverarbeitung auf der Grundlage einer
                Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt
                auch für ein auf diese Bestimmung gestütztes Profiling im Sinne
                von Art. 4 Nr. 4 DSGVO.
              </p>
              <p>
                Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen
                Daten nicht mehr verarbeiten, es sei denn, wir können zwingende
                schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
                Interessen, Rechte und Freiheiten überwiegen, oder die
                Verarbeitung dient der Geltendmachung, Ausübung oder
                Verteidigung von Rechtsansprüchen.
              </p>
              <h3>Empfänger eines Widerspruchs</h3>
              <p>
                Marianne Marschollek <br />
                Aurikelweg 19 <br />
                53840 Troisdorf
              </p>

              <h2>Änderung unserer Datenschutzbestimmungen</h2>
              <p>
                Wir behalten uns vor, diese Datenschutzerklärung anzupassen,
                damit sie stets den aktuellen rechtlichen Anforderungen
                entspricht oder um Änderungen unserer Leistungen in der
                Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer
                Services. Für Ihren erneuten Besuch gilt dann die neue
                Datenschutzerklärung.
              </p>
              <h2>Fragen an den Datenschutzbeauftragten</h2>
              <p>
                Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte
                eine E-Mail oder wenden Sie sich direkt an die für den
                Datenschutz verantwortliche Person in unserer Organisation:
              </p>
              <p></p>
              <p>
                <em>
                  Die Datenschutzerklärung wurde mithilfe der activeMind AG
                  erstellt, den Experten für{' '}
                  <a
                    href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    externe Datenschutzbeauftragte
                  </a>{' '}
                  (Version #2020-09-30).
                </em>
              </p>
            </div>
          </Container>
        </div>
      </section>
    </Layout>
  );
}

export default Behandlungskonzept;
